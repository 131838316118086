import { modalAccessibility } from "@/scripts/faculty/modal-actions.js";

$(document).ready(function () {
  $(".btnGetStartedModal").on("click", function () {
    $(".modalGetStarted").addClass("show");
    $(".modal-main").addClass("show");
    modalAccessibility();
  });

  $("#closeInfoModal, .modal-bkg").on("click", function () {
    $(".modalGetStarted").removeClass("show");
    $(".modal-main").removeClass("show");
  });
});
